






























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ISeqSendStep } from '@/interfaces/seq_sends';
import EditServiceDialog from './EditServiceDialog.vue';

@Component({
  components: {
    EditServiceDialog,
  },
})
export default class ServiceBoardCard extends Vue {
  @Prop() service!: ISeqSendStep;
  @Prop() idx!: number;
  @Prop() editable!: boolean;

  public dialog = false;
}
