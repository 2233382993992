























































import draggable from 'vuedraggable';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ServiceBoardCard from './ServiceBoardCard.vue';
import { EventBus } from './EventBus';

@Component({
  components: {
    draggable,
    ServiceBoardCard,
  },
})
export default class ServicesBoard extends Vue {
  @Prop() columns!: any[];
  @Prop() fromColumn!: any[];
  @Prop() toColumn!: any[];

  public onChange(context: any) {
    if (context.moved && context.moved.element?.id) {
      EventBus.$emit('orderChanged', true);
    } else if (context.removed && context.removed.element?.id) {
      EventBus.$emit('stepRemoved', context.removed.element.id);
    } else if (context.added && !context.added.element?.id) {
      EventBus.$emit('newStepAdded', true);
    }
  }
}
