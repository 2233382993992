
















































import { Vue, Component, Prop, PropSync, Emit } from 'vue-property-decorator';
import { ISeqSendsColumn } from '@/interfaces/seq_sends';
import ServicesBoard from './ServicesBoard.vue';

@Component({
  components: {
    ServicesBoard,
  },
})
export default class Stepper extends Vue {
  @Prop() isEditMode!: boolean;
  @Prop() steps!: any[];
  @Prop() current!: number;
  @Prop() lastStep!: number;
  @Prop() valid!: boolean;
  @Prop() stepForm!: any[];
  @Prop() serviceName!: string;
  @Prop() fromColumn!: ISeqSendsColumn;
  @Prop() toColumn!: ISeqSendsColumn;
  @Prop() columns!: ISeqSendsColumn[];
  @Prop() done!: any;

  @PropSync('serviceName', { type: String }) syncedServiceName!: string;
  @PropSync('current', { type: Number }) syncedCurrent!: number;

  @Emit()
  validate(n: number) {
    this.steps[n].valid = false;
    const isValid = this.$refs.stepForm[n].validate();
    return { isValid, currentStepNum: n };
  }

  @Emit()
  back(n: number) {
    return n;
  }

  public stepComplete(step: number) {
    return this.current > step;
  }

  public stepStatus(step: number) {
    return this.current > step ? 'green' : 'blue';
  }
}
