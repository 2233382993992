














































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ISeqSendStep } from '../../interfaces/seq_sends';
import { EventBus } from './EventBus';

@Component
export default class EditServiceDialog extends Vue {
  public dialog = false;
  public isShow = false;
  public sendersList = [];

  @Prop() service!: ISeqSendStep;

  public submit() {
    if (this.service?.id) {
      EventBus.$emit('stepUpdated', this.service.id);
    }
    this.dialog = false;
  }
}
