
import { Vue, Prop, Component } from 'vue-property-decorator';
import { ISeqSendsColumn } from '@/interfaces/seq_sends';
import { IUserProfile } from '@/interfaces';
import { dispatchGetUserProfile, readUserProfile } from '@/store/main/accessors';

@Component
export default class SeqSendBase extends Vue {
  @Prop() isEditMode!: boolean;

  public current = 1;
  public lastStep = 2;
  public steps = [
    { title: 'newslettersName', rules: [(v) => !!v || 'Обязательное поле'], valid: true, name: 'serviceName' },
    { title: 'selectServices', rules: [(v) => !!v || 'Required.'], valid: true, name: 'serviceChoice' },
  ];
  public valid = false;
  public stepForm = [];
  public serviceId: number = NaN;
  public serviceName = '';

  public fromColumn: ISeqSendsColumn = {
    title: 'services',
    groupName: 'from',
    sort: false,
    services: [],
  };

  public toColumn: ISeqSendsColumn = {
    title: 'selected',
    groupName: 'to',
    sort: true,
    editable: true,
    services: [],
  };

  public columns: ISeqSendsColumn[] = [this.fromColumn, this.toColumn];

  public async getUserProfile(): Promise<IUserProfile | null> {
    await dispatchGetUserProfile(this.$store);
    return readUserProfile(this.$store);
  }

  public back(n: number) {
    this.current = n;
  }

  public convertDurationToSeconds(duration: string) {
    const [hours, minutes, seconds] = duration.split(':');
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }

  public validate({ isValid, currentStepNum }) {
    if (isValid) {
      this.steps[currentStepNum].valid = true;
      // continue to next
      this.current = currentStepNum + 2;
    }
  }
}
